import '@babel/polyfill';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App.vue';
import '@fortawesome/fontawesome-free/css/all.css';
import { default as msalPlugin } from "vue-msal-browser";

const msalConfig = {
    auth: {
      tenant: process.env.VUE_APP_TENANT,
      clientId: process.env.VUE_APP_CLIENT_ID,
      authority: process.env.VUE_APP_AUTHORITY,
      redirectUri: window.location.origin, // It has to be configured on your Azure tenant
      scopes: [process.env.VUE_APP_SCOPES]
    },
    cache: {
      cacheLocation: 'sessionStorage'
    },
    graph: {
      url: 'https://graph.microsoft.com',
      scopes: 'User.Read'
    },
    mode: "redirect"
}

Vue.config.productionTip = false;

Vue.use(Vuetify, {
    iconfont: 'fa',
});

Vue.use(msalPlugin, msalConfig);

const google_maps_api_key = 'AIzaSyCo3-NxVD_M08lVj0w0ohIGJerKfgrJy6Q';
Vue.use(VueGoogleMaps, {
    load: {
        key: google_maps_api_key,
        libraries: ['places', 'geometry'], // necessary for places input
    },
});

new Vue({
    render: (h) => h(App),
}).$mount('#app');
