<template>
    <div class="data" id="id-td-MapView">
        <div>
            <!-- Global loading component -->
            <v-progress-circular
                class="loader"
                v-if="loading"
                :size="70"
                :width="7"
                color="green"
                indeterminate
            ></v-progress-circular>
        </div> 
        <div id="id-td-header" style="background: #43B02A; position: relative; height: 78px">
            <img
                style="margin: 10px; left: 30px; position: relative;"
                width="56"
                height="58"
                src="@/assets/hyliion_logo_with_text.png"
                title="v0.8.60"
            />
            <p class="page-title">{{ appTitle }}</p>
            <v-btn-toggle class="kioskToggleGroup" v-model="toggle_exclusive" mandatory dark @change="onDisplayModeChangedHandler">
                <v-btn flat>
                    <v-icon size="16px" color="#43B02A">fa-laptop</v-icon>
                </v-btn>
                <v-btn flat>
                    <v-icon size="16px" color="#43B02A">fa-tv</v-icon>
                </v-btn>
            </v-btn-toggle>
        </div>
        <v-app id="inspire" dark>
            <v-tabs v-model="active" color="#43B02A" dark slider-color="red" style="width: 100%" @change="switchTitle">
                <v-tab :key="1" ripple>Map</v-tab>
                <v-tab :key="2" ripple>Health</v-tab>
                <v-tab-item :key="1">
                    <div class="sideTable" v-if="toggle_exclusive === 0">
                        <v-data-table
                            :headers="headers"
                            :items="markers"
                            :key="sideKey"
                            :disable-initial-sort="true"
                            :rows-per-page-items="rowsPerPageItems"
                            v-bind:pagination-sync="pagination"
                            class="elevation-1"
                        >
                            <template slot="items" slot-scope="props">
                                <tr @click="moveMap(props.item)" :key="props.index">
                                    <td style="cursor: pointer;" class="text-xs-center narrow-col">{{ props.item.company }}</td>
                                    <td style="cursor: pointer;" class="text-xs-center narrow-col">{{ props.item.number }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                    <gmap-map ref="gmapRef" id="map" :center="center" :zoom="5">
                        <!-- <gmap-marker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        :title="m.title"
                        :icon="m.content"
                        @click="center=m.position"
                        ></gmap-marker>-->
                    </gmap-map>
                </v-tab-item>

                <v-tab-item :key="2">
                    <div class="health-table" id="id-health-table">
                        <v-data-table
                            :headers="healthLabels"
                            :items="truckData"
                            :custom-sort="healthTableCustomSort"
                            :disable-initial-sort="true"
                            :key="healthKey"
                            :rows-per-page-items="rowsPerHealthItems"
                            v-bind:pagination-sync="healthPagination"
                            class="elevation-1"
                        >
                            <template slot="items" slot-scope="props">
                                <tr>
                                    <td style="cursor: default;" class="text-xs-center narrow-col">{{ props.item.company }}</td>
                                    <td style="cursor: default;" class="text-xs-center narrrow-col">{{ props.item.number }}</td>
                                    <td style="cursor: default;" class="text-xs-center narrow-col">{{ props.item.address }}</td>
                                    <td style="cursor: default;" class="text-xs-center narrow-col">
                                        <v-menu offset-y>
                                            <v-icon :class="props.item.status" slot="activator">{{ props.item.statusIcon }}</v-icon>
                                            <v-list>
                                                <v-list-tile
                                                    v-for="(status, index) in statusItems"
                                                    :key="index"
                                                    :class="status.title"
                                                    @click="setStatus(props.item, status.title, 'status')"
                                                    >{{ status.title }}</v-list-tile
                                                >
                                            </v-list>
                                        </v-menu>
                                    </td>
                                    <!-- <td style="cursor: default;" class="text-xs-center narrow-col">
                                        <v-menu offset-y>
                                            <v-icon
                                                :class="props.item.truck_status"
                                                slot="activator"
                                            >{{props.item.truckStatusIcon}}</v-icon>
                                            <v-list>
                                                <v-list-tile
                                                    v-for="(status, index) in statusItems"
                                                    :key="index"
                                                    :class="status.title"
                                                    @click="setStatus(props.item, status.title, 'truckStatus')"
                                                >{{status.title}}</v-list-tile>
                                            </v-list>
                                        </v-menu>
                                    </td>-->
                                    <!-- <td
                                        style="cursor: default;"
                                        class="text-xs-center narrow-col"
                                    >{{ props.item.daysSinceCombined }}</td>-->
                                    <!-- <td
                                        style="cursor: default;"
                                        class="text-xs-left narrow-col"
                                    >{{ props.item.alertText }}</td>-->
                                    <td 
                                        style="cursor: pointer;" 
                                        class="text-xs-center narrow-col" 
                                        :class="props.item.dtc_color"
                                        @click="displayDtc(props.item.vin,props.item.dtc)">{{props.item.dtc}}</td>
                                    <td
                                        style="cursor: default; font-weight: bold;"
                                        class="text-xs-center narrow-col"
                                        :class="props.item.daysStatus"
                                    >
                                        <!-- <v-menu offset-y>
                                            <v-icon
                                                :class="props.item.service_status"
                                                slot="activator"
                                            >{{props.item.daysStatusIcon}}</v-icon>
                                            <v-list>
                                                <v-list-tile
                                                    v-for="(status, index) in statusItems"
                                                    :key="index"
                                                    :class="status.title"
                                                >{{status.title}}</v-list-tile>
                                            </v-list>
                                        </v-menu>-->
                                        {{ props.item.daysSince }}
                                    </td>
                                    <td style="cursor: pointer;" class="text-xs-left narrow-col">
                                        <v-edit-dialog :return-value.sync="props.item.issue" lazy @save="setIssue(props.item)">
                                            {{ props.item.issue }}
                                            <template v-slot:input>
                                                <v-text-field
                                                    v-model="props.item.issue"
                                                    :rules="[max100chars]"
                                                    label="Edit"
                                                    single-line
                                                    counter
                                                ></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </td>
                                    <td style="cursor: pointer;" class="text-xs-left narrow-col">
                                        <v-edit-dialog :return-value.sync="props.item.action" lazy @save="setAction(props.item)">
                                            {{ props.item.action }}
                                            <template v-slot:input>
                                                <v-text-field
                                                    v-model="props.item.action"
                                                    :rules="[max100chars]"
                                                    label="Edit"
                                                    single-line
                                                    counter
                                                ></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </v-tab-item>
            </v-tabs>   
        <div>
            <v-dialog v-model="dtcHover">
            <v-btn  style="float:right;" @click="dtcHover = false">
                <v-icon color="rgba(255,255,255,0.7)">remove_circle</v-icon>
            </v-btn>
            <v-data-table
                :headers="dtcLabels"
                :items="dtcItems"
                :items-per-page="5"
                class="elevation-1"
            >
                <template v-slot:items="props">
                <td class="text-xs-center">{{ props.item.dtc }}</td>
                <td class="text-xs-center">{{ props.item.name }}</td>
                <td class="text-xs-center">{{ props.item.activationTime }}</td>
                <td class="text-xs-center">{{ props.item.deactivationTime }}</td>
                </template>
            </v-data-table>
            </v-dialog>
        </div>                 
        </v-app> 
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import RichMarker from 'googlemaps-rich-marker-web';
import MarkerClusterer from '@google/markerclusterer';
import {clearInterval, setInterval} from 'timers';

export default {
    name: 'MapView',
    props: {
        msg: String,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Customer',
                    align: 'center',
                    sortable: true,
                    value: 'company',
                },
                {
                    text: 'Truck #',
                    align: 'center',
                    sortable: true,
                    value: 'number',
                },
            ],
            max50chars: (v) => v.length <= 50 || 'Input too long!',
            max100chars: (v) => v.length <= 100 || 'Input too long!',
            healthLabels: [
                {
                    text: 'Customer',
                    align: 'center',
                    sortable: true,
                    value: 'company',
                },
                {
                    text: 'Truck #',
                    align: 'center',
                    sortable: true,
                    value: 'number',
                    width: '5%',
                },
                { text: 'Location', align: 'center', value: 'address', width: '13%', sortable: false },
                { text: '6X4HE', align: 'center', sortable: true, value: 'statusIcon', width: '4.5%' },
                // { text: "Truck", align: "center", sortable: true, value: "truckStatusIcon", width: "4.5%" },
                { text: "Active DTCs", align: "center", sortable: true, value: "dtc", width: "6%" },
                // { text: "Comm / Alert", align: "center", sortable: true, value: "daysSinceCombined", width: "6%" },
                // { text: "Alert Description", align: "left", value: "alertText", sortable: false },
                { text: 'Days Up/Down', align: 'center', sortable: true, value: 'daysSince', width: '6%' },
                { text: 'Issues', align: 'left', value: 'issue', sortable: false },
                { text: 'Service (Who/When)', align: 'left', value: 'action', sortable: false },
            ],
            authConfig: {},
            authenticated: false,
            appTitle: 'Truck Map',
            active: 0,
            center: { lat: 30.5052, lng: -97.8203 },
            hqLocation: { lat: 30.502378, lng: -97.800371 },
            zoom: 5,
            markers: [],
            markerCluster: null,
            infoWindow: [],
            mapInterval: null,
            shadowInterval: null,
            intervalTimer: null,
            markerTimer: 0,
            mapBounds: null,
            truckUpdateCounter: 0,
            rotateCounter: 0,
            loading: false,
            truckData: [],
            dtcHover: false,
            dtcLabels: [
                { text: 'DTC', align: 'center', value: 'dtc', sortable: false },
                { text: 'Human Readable Name', align: 'center', value: 'name', sortable: false },
                { text: 'Activation Time', align: 'center', value: 'activationTime', sortable: false },
                { text: 'Deactivation Time', align: 'center', value: 'deactivationTime', sortable: false }
            ],
            dtcItems: [],
            companyName: [
                {
                    oldName: 'CREngland',
                    properName: 'C.R.England',
                },
            ],
            companyIcons: [
                {
                    company: 'C.R.England',
                    logoName: 'logo_crengland.png',
                    width: '83',
                    height: '29',
                    top: '1px',
                },
                {
                    company: 'Hyliion',
                    logoName: 'logo_hyliion.png',
                    width: '23',
                    height: '27',
                    top: '2px',
                },
                {
                    company: 'Wegmans',
                    logoName: 'logo_wegmans.png',
                    width: '68',
                    height: '24',
                    top: '4px',
                },
                {
                    company: 'PAM',
                    logoName: 'logo_pam.png',
                    width: '81',
                    height: '23',
                    top: '5px',
                },
                {
                    company: 'Perdue',
                    logoName: 'logo_perdue.png',
                    width: '37',
                    height: '26',
                    top: '2px',
                },
                {
                    company: 'EGL',
                    logoName: 'logo_egl.png',
                    width: '64',
                    height: '26',
                    top: '2px',
                },
                {
                    company: 'Eagle',
                    logoName: 'logo_eagle.png',
                    width: '47',
                    height: '30',
                    top: '2px',
                },
                {
                    company: 'Ryder',
                    logoName: 'logo_ryder.png',
                    width: '68',
                    height: '22',
                    top: '4px',
                },
                {
                    company: 'Penske',
                    logoName: 'logo_penske.jpg',
                    width: '68',
                    height: '22',
                    top: '4px',
                },
            ],
            companyList: [],
            companyTitle: '',
            baseTruckIcon: '/images/logos/truck_green_lg.png',
            markerContent:
                '<div class="hymarker" style="display:block;position:relative;width:220px;height:43px;background-image:url(/images/logos/truck_green_lg.png);z-index:10;background-size:cover;">',
            myGoogleMap: null,
            healthKey: 1,
            sideKey: 1,
            showKey: false,
            animProp: null,
            queryPathAuth: process.env.VUE_APP_API_PATH_BASE,
            queryPathTrucks: process.env.VUE_APP_API_PATH_BASE + "trucks/td",
            queryPathDTCs:  process.env.VUE_APP_API_PATH_BASE + "trucks/dtc",
            direction: [
                "north",
                "south",
                "east",
                "west",
                "northeast",
                "northwest",
                "southeast",
                "southwest"
            ],
            rowsPerPageItems: [15],
            pagination: {
                rowsPerPage: 15,
                page: 1,
                total: 0,
            },
            rowsPerHealthItems: [50],
            healthPagination: {
                rowsPerPage: 50,
                page: 1,
                total: 0,
            },
            toggle_exclusive: 0,
            kioskMode: false,
            mapControlsShow: {
                zoomControl: true,
                mapTypeControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
            },
            mapControlsHide: {
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
            },
            shortStatusItems: [{ title: 'Normal' }, { title: 'Critical' }],
            statusItems: [
                { title: 'Normal' },
                { title: 'Warning' },
                { title: 'Critical' },
                { title: 'Service' },
                { title: 'TruckService' },
            ],
            statusIcons: {
                Normal: 'fa-thumbs-up',
                Warning: 'fa-exclamation-triangle',
                Critical: 'fa-medkit',
                Service: 'fa-ambulance',
                TruckService: 'fa-truck-moving',
            },
            statusOrder: {
                Normal: 1,
                Warning: 2,
                Critical: 3,
                Service: 4,
                TruckService: 5,
            },
            maxAutoZoomLevel: 8,
            markerTimerId: null,
            maxRecordAgeSeconds: 7776000, // Setting this to 3 months to match the old API 
            countToNextPopulateCall: 60, // 60 minutes: 60 calls to updateTrucks before another call to populateTrucks
            updateCallCount: 0, // Calls updateTruck count.  Reset to 0 when populateTrucks is called
            // Map Style with all labels off
            labelsOffStyle: [
                {
                    featureType: 'administrative.land_parcel',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'administrative.neighborhood',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'administrative.province',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
                {
                    featureType: 'water',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                },
            ],
            labelsOnStyle: [
                {
                    featureType: 'administrative.land_parcel',
                    elementType: 'labels',
                    stylers: [{ visibility: 'on' }],
                },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels',
                    stylers: [{ visibility: 'on' }],
                },
                {
                    featureType: 'administrative.neighborhood',
                    elementType: 'labels',
                    stylers: [{ visibility: 'on' }],
                },
                {
                    featureType: 'administrative.province',
                    elementType: 'labels',
                    stylers: [{ visibility: 'on' }],
                },
                {
                    featureType: 'water',
                    elementType: 'labels',
                    stylers: [{ visibility: 'on' }],
                },
            ],
        };
    },
    watch: {
        authenticated: function (newVal, oldVal){
            if (oldVal === false && newVal === true){
                let vm = this;
                vm.intervalTimer = Math.round(new Date().getTime() / 1000);
                // Set up google maps
                vm.$gmapApiPromiseLazy().then(() => {
                    vm.myGoogleMap = vm.$refs.gmapRef.$mapObject;

                    // Set default map type to Terrain instead of Roadmap.
                    // eslint-disable-next-line
                    vm.mapControlsShow.mapTypeId = google.maps.MapTypeId.TERRAIN;
                    // eslint-disable-next-line
                    vm.mapControlsHide.mapTypeId = google.maps.MapTypeId.TERRAIN;

                    vm.onDisplayModeChangedHandler();
                    // eslint-disable-next-line
                    google.maps.event.addListener(vm.myGoogleMap, 'zoom_changed', function() {
                        // vm.buildMapMarkers(null);
                    });
                    // This filters out the trucks that haven't been updated in the set amount of time.
                    let maxRecordDate = new Date();
                    let maxRecordDateSeconds = Math.round((maxRecordDate.getTime() / 1000) - vm.maxRecordAgeSeconds);
                    vm.populateTrucks(vm.queryPathTrucks + `?removeUninstalled=true&queryTime=${maxRecordDateSeconds}`);
                    vm.setUpdateInterval(60000);
                    window.addEventListener('resize', _.debounce(vm.calculateElementLayout, 150));
                });
            }
        }
    },
    mounted() {
        const accounts = this.$msal.getAllAccounts();
        if (accounts.length === 0) {
            console.log("Not authenticated");
            this.$msal.authenticate().then(this.authenticated = true);
        } else {
            this.authenticated = true;
            console.log("authenticated");
        }
    },
    computed: {},
    methods: {
        updateAuthConfig: async function () {
            let vm = this;
            let msalInstance = vm.$msal;
            let tokenResponse = {};
            let account = msalInstance.getAllAccounts()[0];
            if (!account){
                console.log("No account found");
                await this.$msal.authenticate();
                account = msalInstance.getAllAccounts()[0];
            }
            tokenResponse = await msalInstance.getSilentToken(account, msalInstance.config.auth.scopes);
            vm.authConfig = {
                headers: { Authorization: 'Bearer ' + tokenResponse.accessToken},
                crossdomain: true // This should probably be removed in production
            };
        },
        isNull: function(o) {
            return o === null;
        },
        isNullOrUndefined: function(o) {
            return o === null || o === undefined;
        },
        tableKey: function() {
            return Math.round(Math.random() * (90000 - 1) + 1);
        },
        sleep: function(timer) {
            return new Promise((resolve) => setTimeout(resolve, timer));
        },
        randomColor: function() {
            return Math.floor(Math.random() * 16777215).toString(16);
        },
        cleanMarkers: function() {
            let vm = this;
            if (vm.myGoogleMap) {
                for (let marker of vm.markers) {
                    marker.setMap(null);
                }
                vm.markers = [];
            }
        },
        switchTitle: function() {
            let vm = this;
            if (vm.kioskMode) {
                vm.appTitle = vm.active === 0 ? 'Truck Map ' + vm.companyTitle : 'Health Monitor';
            } else {
                vm.appTitle = vm.active === 0 ? 'Truck Map' : 'Health Monitor';
            }
        },
        deDupeArray: function(rawArray) {
            let cookedArray = rawArray.filter(function(el, i, arr) {
                return arr.indexOf(el) === i;
            });
            return cookedArray;
        },
        sortTruckArray: function(a, b) {
            return a.company.localeCompare(b.company) || a.number - b.number;
        },
        markersSortExtra: function() {
            let vm = this;
            let hyArray = [],
                tmpArray = [];
            let tLength = vm.markers.length;
            //create dupe array for just Hyliion trucks
            for (let i = 0; i < tLength; i++) {
                if (vm.markers[i].company.toLowerCase() === 'hyliion') {
                    hyArray.push(vm.markers[i]);
                } else {
                    tmpArray.push(vm.markers[i]);
                }
            }
            tmpArray.sort(vm.sortTruckArray);
            hyArray.sort(vm.sortTruckArray);
            vm.markers = _.concat(tmpArray, hyArray);
            // console.log('markers re-sorted...');
            vm.sideKey = vm.tableKey();
        },
        truckSortExtra: function() {
            let vm = this;
            let hyArray = [],
                tmpArray = [];
            let tLength = vm.truckData.length;
            //create dupe array for just Hyliion trucks
            for (let i = 0; i < tLength; i++) {
                if (vm.truckData[i].company.toLowerCase() === 'hyliion') {
                    hyArray.push(vm.truckData[i]);
                } else {
                    tmpArray.push(vm.truckData[i]);
                }
            }
            tmpArray.sort(vm.sortTruckArray);
            hyArray.sort(vm.sortTruckArray);
            vm.truckData = _.concat(tmpArray, hyArray);
            // console.log('trucks re-sorted...');
            vm.healthKey = vm.tableKey();
        },
        healthTableCustomSort(items, index, isDesc) {
            let vm = this;
            items.sort((a, b) => {
                // console.log('Index is -- ' + index);
                if (index === 'statusIcon') {
                    if (!isDesc) {
                        return vm.statusOrder[a.status] < vm.statusOrder[b.status] ? -1 : 1;
                    } else {
                        return vm.statusOrder[b.status] < vm.statusOrder[a.status] ? -1 : 1;
                    }
                } else if (index === 'truckStatusIcon') {
                    if (!isDesc) {
                        return vm.statusOrder[a.truck_status] < vm.statusOrder[b.truck_status] ? -1 : 1;
                    } else {
                        return vm.statusOrder[b.truck_status] < vm.statusOrder[a.truck_status] ? -1 : 1;
                    }
                } else if (index === 'daysSinceCombined') {
                    if (!isDesc) {
                        return a.truckDaysSince < b.truckDaysSince ? -1 : 1;
                    } else {
                        return b.truckDaysSince < a.truckDaysSince ? -1 : 1;
                    }
                } else if (index === 'dtc') {
                    if (!isDesc) {
                        return a.dtc < b.dtc ? -1 : 1;
                    } else {
                        return b.dtc < a.dtc ? -1 : 1;
                    }
                } else if (index !== null) {
                    if (!isDesc) {
                        return a.company.localeCompare(b.company) || a.number - b.number;
                    } else {
                        return b.company.localeCompare(a.company) || b.number - a.number;
                    }
                }
            });
            return items;
        },
        onDisplayModeChangedHandler: function() {
            let vm = this;
            vm.kioskMode = vm.toggle_exclusive;
            vm.setFullScreen();
            vm.calculateElementLayout();
            vm.setMapControls(vm.kioskMode);
        },
        setFullScreen: async function() {
            let vm = this;
            let mapView = document.getElementById('id-td-MapView');
            vm.markerTimer = 10000;
            if (vm.kioskMode) {
                await mapView.requestFullscreen();
                vm.markerTimerId = setInterval(vm.markerRotation, vm.markerTimer);
                await vm.markerRotation();
            } else {
                if (!vm.isNullOrUndefined(vm.markerIntervalTimer)) {
                    clearInterval(vm.markerTimerId);
                }
                if (vm.markerCluster) {
                    await vm.clusterWindows('hide');
                    await vm.sleep(200);
                    await vm.destroyClusters();
                    vm.buildMapMarkers(null);
                }
                // vm.appTitle = 'Truck Map';
                vm.switchTitle();
                document.fullscreenElement
                    ? document.exitFullscreen()
                    : console.log('Requested exitFullscreen but we are not in full screen mode.');
            }
        },
        setMapControls: function() {
            let vm = this;
            vm.myGoogleMap.setOptions(vm.kioskMode ? vm.mapControlsHide : vm.mapControlsShow);
        },
        calculateElementLayout: function() {
            let vm = this;
            let innerHeight = parseInt(window.innerHeight);
            let tdHeaderHeight = document.getElementById('id-td-header').clientHeight;
            let gmapElement = document.getElementById('map');
            let newHeight = innerHeight - tdHeaderHeight;
            gmapElement.style.height = newHeight + 'px';
            gmapElement.style.width = (vm.kioskMode ? 100 : 80) + '%';
            let healthTableElement = document.getElementById('id-health-table');
            healthTableElement.style.height = newHeight + 'px';
            let vTabsElement = document.getElementsByClassName('v-tabs__container')[0];
            vTabsElement.style.display = vm.kioskMode ? 'none' : 'flex';
        },
        convertEpochTime: function(utcSeconds) {
            if(!utcSeconds) return '';
            let localDate = new Date(0);
            localDate.setUTCSeconds(utcSeconds);
            return localDate.getMonth() + 1 + '-' + localDate.getDate() + '-' + localDate.getFullYear() + ' ' + localDate.getHours() + ':' + localDate.getMinutes() + ':' + localDate.getSeconds();
        },
        getDaysDelta: function(alertTime) {
            let date = new Date();
            let nowTime = Math.round(date.getTime() / 1000);
            let timeDelta = nowTime - alertTime;
            return Math.round(Math.floor(timeDelta / 86400));
        },
        convertCompanyName: function(pName) {
            let vm = this;
            let nameLength = vm.companyName.length;
            for (let i = 0; i < nameLength; i++) {
                if (pName === vm.companyName[i].properName) {
                    return vm.companyName[i].oldName;
                }
            }
            return pName;
        },
        getCompanyIcon: function(company) {
            let vm = this;
            for (let icon of vm.companyIcons) {
                if (company === icon.company) {
                    return icon;
                }
            }
            return null;
        },
        setUpdateInterval: function(timer) {
            let vm = this;
            vm.mapInterval = setInterval(function () {
                vm.updateTrucks(
                    vm.queryPathTrucks + "?removeUninstalled=true&queryTime=" + vm.intervalTimer
                );
                vm.intervalTimer = Math.round(new Date().getTime() / 1000);
                // vm.myGoogleMap.panTo(vm.center);
                // vm.myGoogleMap.setZoom(6);
                // console.log("interval passed...");
                vm.checkCallToPopulateTrucks();
                // vm.healthKey = vm.tableKey();
            }, timer);
        },
        stopUpdateInterval: function() {
            let vm = this;
            window.clearInterval(vm.mapInterval);
        },
        checkCallToPopulateTrucks: function() {
            let vm = this;
            vm.updateCallCount += 1;
            if (vm.updateCallCount > vm.countToNextPopulateCall) {
                console.log(
                    `call populateTrucks: call count of ${vm.updateCallCount} exceeded threshold count of ${vm.countToNextPopulateCall}.`
                );
                vm.updateCallCount = 0;
                vm.populateTrucks(vm.queryPathTrucks + "?removeUninstalled=true");
            }
        },
        moveMap: function(pin) {
            let vm = this;
            vm.myGoogleMap.panTo(pin.position);
            vm.myGoogleMap.setZoom(6);
        },
        buildCompanyList: function(listArr) {
            let vm = this;
            vm.companyList.length = 0;
            let cleanArr = vm.deDupeArray(listArr);
            vm.companyList = cleanArr.slice();
            vm.companyList.unshift('All Trucks');
            vm.companyList.sort();
        },
        markerRotation: async function() {
            let vm = this;
            // If we are not in Kiosk Mode or We are showing the health monitor, return here.
            if (!vm.kioskMode || vm.active !== 0) {
                clearInterval(vm.markerTimerId);
                return;
            }
            if (vm.markerCluster) {
                vm.clusterWindows('hide');
                await vm.sleep(200);
                await vm.destroyClusters();
            }
            // eslint-disable-next-line
            vm.mapBounds = new google.maps.LatLngBounds();
            // eslint-disable-next-line
            let centerUSA = new google.maps.LatLng(37.09024, -95.712891);
            let cIndex = vm.rotateCounter % vm.companyList.length;
            let item = vm.companyList[cIndex];
            await vm.sleep(100);
            vm.buildMapMarkers(item);
            await vm.sleep(300);
            let markerLength = vm.markers.length;
            vm.companyTitle = '(' + vm.companyList[cIndex] + ')';
            vm.appTitle = 'Truck Map ' + vm.companyTitle;
            // console.log('Rotation --> ' + vm.companyTitle + ' -- # of markers in rotation --> ' + markerLength);
            for (let i = 0; i < markerLength; i++) {
                if (vm.markers[i].company === item || item === 'All Trucks') {
                    vm.mapBounds.extend(vm.markers[i].position);
                }
            }
            // Add a marker cluster to manage the markers.
            vm.createClusters();
            _.delay(vm.clusterWindows, 100, 'show');
            if (item === 'All Trucks') {
                // vm.markerTimerId = setTimeout(vm.markerRotation, 30000);
                vm.myGoogleMap.setOptions({ styles: vm.labelsOffStyle });
                vm.myGoogleMap.setOptions({ maxZoom: vm.maxAutoZoomLevel });
                await vm.sleep(600);
                vm.myGoogleMap.setCenter(centerUSA);
                vm.myGoogleMap.setZoom(5);
                vm.myGoogleMap.setOptions({ maxZoom: vm.undefined });
                vm.myGoogleMap.setOptions({ styles: vm.labelsOnStyle });
                // console.log('All trucks...');
            } else {
                // vm.markerTimerId = setTimeout(vm.markerRotation, 10000);
                // Set max zoom level before fitBounds and then reset after fit bounds
                vm.myGoogleMap.setOptions({ styles: vm.labelsOffStyle });
                vm.myGoogleMap.setOptions({ maxZoom: vm.maxAutoZoomLevel });
                await vm.sleep(600);
                vm.myGoogleMap.fitBounds(vm.mapBounds);
                vm.myGoogleMap.setOptions({ maxZoom: vm.undefined });
                vm.myGoogleMap.setOptions({ styles: vm.labelsOnStyle });
            }

            vm.rotateCounter++;

            if (vm.companyTitle === '(All Trucks)') {
                // console.log('Resetting marker rotation to 60 seconds for All Trucks ...');
                clearInterval(vm.markerTimerId);
                vm.markerTimer = 60000;
                vm.markerTimerId = setInterval(vm.markerRotation, vm.markerTimer);
            } else {
                // console.log('Resetting marker rotation to 10 seconds for others ...');
                clearInterval(vm.markerTimerId);
                vm.markerTimer = 10000;
                vm.markerTimerId = setInterval(vm.markerRotation, vm.markerTimer);
            }
        },
        dtcColor: function(count){
            let code = '';
            switch(count){
                case 1:
                    code = 'Critical';
                break;
                case 2:
                    code = 'SevereWarning';
                break;
                case 3:
                    code = 'Warning';
                break;
                default:
                    code = 'Normal';
                break;
            }
            return code;
        },
        populateTrucks: async function(source) {
            let vm = this;
            await vm.updateAuthConfig();
            await axios
                // .get('/data/test_data.json')
                .get(source, vm.authConfig)
                .then(response => {
                    let dataLength = response.data.length;
                    // let truckHeading = 0, headingText = "";
                    let tmpArray = [];
                    // let currDateTime = Math.round(new Date().getTime() / 1000);
                    if (dataLength > 0) {
                        vm.truckData.length = 0;
                        vm.truckData = response.data.slice();
                        for (let truck of vm.truckData) {
                            if (truck.speed === 0 || vm.isNullOrUndefined(truck.speed)) {
                                truck.stopCheck = 1;
                            } else {
                                truck.stopCheck = 0;
                            }
                            tmpArray.push(truck.company);
                            vm.$set(truck, 'name', truck.truckinfo.name);
                            vm.$set(truck, 'number', truck.truckinfo.number);
                            vm.$set(truck, 'address', truck.address);
                            if (!vm.isNullOrUndefined(truck.alert_info)) {
                                vm.$set(truck, 'alertText', truck.alert_info.Info);
                                let tmpDate = truck.alert_info.ReportTime;
                                let numOfDaysAlert = vm.getDaysDelta(tmpDate);
                                truck.truckDaysSince = vm.getDaysDelta(truck.report_time);
                                vm.$set(truck, 'daysSinceCombined', `${truck.truckDaysSince} / ${numOfDaysAlert}`);
                            }
                            vm.$set(truck, 'action', truck.action === null ? ' ' : truck.action);
                            vm.$set(truck, 'issue', truck.issue === null ? ' ' : truck.issue);
                            vm.$set(truck, 'daysSince', truck.daysSince);

                            //const dtc = Math.floor(Math.random() * 4); //***Temp***** Will grab from API to get count of codes triggered
                            vm.$set(truck, 'dtc', truck.active_dtcs || 0);
                            vm.$set(truck, 'dtc_color', vm.dtcColor(truck.max_severity || 0));

                            if (truck.daysSince >= 0) {
                                truck.daysStatus = 'Normal';
                            } else {
                                truck.daysStatus = 'Critical';
                            }

                            // This is set from the Health Monitor table by clicking on the status icon.
                            vm.$set(truck, 'status', truck.status);
                            // Decide the icon to show in truck status column/field based on the truck status.
                            let truckStatusIcon = vm.statusIcons[truck.truck_status];
                            vm.$set(truck, 'truckStatusIcon', truckStatusIcon);
                            // Decide the icon to show in (6X4HE) status column/field based on the (6X4HE) status.
                            let statusIcon = vm.statusIcons[truck.status];
                            vm.$set(truck, 'statusIcon', statusIcon);

                            // Using $set above, adds reactive properties of to the nested object in the
                            // truckData array.  So in this case set is being used to the status property
                            // for the truckData objects is reactive and we can change the icon of in the
                            //  status column.
                            // when the status of the truck changes by binding a class based on the status of
                            // the truck.

                            // From the official Vue documentation see section on Change Detection Caveats:
                            // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats and
                            // https://vuejs.org/v2/guide/list.html#Array-Change-Detection

                            // Also see the accepted answers to the SO questions below:
                            // https://stackoverflow.com/questions/44800470/vue-js-updated-array-item-value-doesnt-update-in-page   see accetped answer

                            // https://stackoverflow.com/questions/42807888/vuejs-and-vue-set-update-array
                        }
                        vm.buildCompanyList(tmpArray);
                        vm.truckUpdateCounter++;
                        vm.buildMapMarkers(null);
                        // vm.healthKey = vm.tableKey();
                    }
                    // console.log(JSON.parse(JSON.stringify(vm.markers)));
                    vm.truckSortExtra();
                })
                .catch((error) => {
                    console.log('Axios error on populateTrucks() ==> ' + error);
                });
        },
        updateTrucks: async function(source) {
            let vm = this;
            await vm.updateAuthConfig();
            await axios
                // .get('/data/test_data.json')
                .get(source, vm.authConfig)
                .then(response => {
                    let resArr = response.data;
                    let dataLength = response.data.length;
                    let testLength = vm.truckData.length;
                    if (dataLength > 0) {
                        for (let i = 0; i < dataLength; i++) {
                            if (resArr[i].speed === 0 || typeof resArr[i].speed === 'undefined') {
                                resArr[i].stopCheck = 1;
                            } else {
                                resArr[i].stopCheck = 0;
                            }
                            // For now just set to 'north'. Soon the cardinal direction set from response
                            resArr[i].direction = 'north';
                            for (let j = 0; j < testLength; j++) {
                                if (resArr[i].truckinfo.name === vm.truckData[j].truckinfo.name) {
                                    vm.truckData[j].position.lat = resArr[i].position.lat;
                                    vm.truckData[j].position.lng = resArr[i].position.lng;
                                    // vm.truckData[j].heading = resArr[i].heading;
                                    vm.truckData[j].direction = resArr[i].direction;
                                    vm.truckData[j].reportTime = resArr[i].reportTime;
                                    if (resArr[i].stopCheck === 0) {
                                        vm.truckData[j].stopCheck = 0;
                                    } else {
                                        vm.truckData[j].stopCheck += resArr[i].stopCheck;
                                    }
                                    vm.$set(vm.truckData[j], 'name', resArr[i].truckinfo.name);
                                    vm.$set(vm.truckData[j], 'number', resArr[i].truckinfo.number);
                                    vm.$set(vm.truckData[j], 'address', resArr[i].address);
                                    if (resArr[i].alert_info) {
                                        vm.$set(vm.truckData[j], 'alertText', resArr[i].alert_info.Info);
                                        let tmpDate = resArr[i].alert_info.ReportTime;
                                        let numOfDaysAlert = vm.getDaysDelta(tmpDate);
                                        vm.truckData[j].truckDaysSince = vm.getDaysDelta(resArr[i].report_time);
                                        // vm.$set(vm.truckData[j], 'alertDate', numOfDaysAlert);
                                        vm.$set(
                                            vm.truckData[j],
                                            'daysSinceCombined',
                                            `${vm.truckData[j].truckDaysSince} / ${numOfDaysAlert}`
                                        );
                                    }
                                    vm.$set(vm.truckData[j], 'action', resArr[i].action === null ? ' ' : resArr[i].action);
                                    vm.$set(vm.truckData[j], 'issue', resArr[i].issue === null ? ' ' : resArr[i].issue);

                                    // Decide the icon to show in Days Up/Down column/field based on the communications status.
                                    vm.$set(vm.truckData[j], 'daysSince', resArr[i].daysSince);
                                    vm.truckData[j].in_service = resArr[i].in_service;

                                    if (vm.truckData[j].daysSince >= 0) {
                                        vm.truckData[j].daysStatus = 'Normal';
                                    } else {
                                        vm.truckData[j].daysStatus = 'Critical';
                                    }

                                    // Decide the icon to show in truck status column/field based on the truck status.
                                    vm.$set(vm.truckData[j], 'truck_status', resArr[i].truck_status);
                                    let truckStatusIcon = vm.statusIcons[resArr[i].truck_status];
                                    vm.$set(vm.truckData[j], 'truckStatusIcon', truckStatusIcon);

                                    // Decide the icon to show in the status (6X4HE) column/field based on the (6X4HE) status.
                                    vm.$set(vm.truckData[j], 'status', resArr[i].status);
                                    let statusIcon = vm.statusIcons[resArr[i].status];
                                    vm.$set(vm.truckData[j], 'statusIcon', statusIcon);
                                }
                            }
                        }
                        vm.truckUpdateCounter++;
                        vm.buildMapMarkers(null);
                    }
                    vm.truckSortExtra();
                })
                .catch((error) => {
                    console.log('Axios error on updateTrucks() ==> ' + error);
                });
        },
        displayDtc: async function(vin, count) {
            let vm = this;

            this.dtcItems = [];
            vm.loading = true;

            await vm.getTruckDtcInfo(vin,count);

            vm.loading = false;
            vm.dtcHover = true;    
        },
        getTruckDtcInfo: async function(vin, count){
            let vm = this;
            await vm.updateAuthConfig();
            await axios.get(`${this.queryPathDTCs}/${vin}?count=${count}`, vm.authConfig)
            .then(response => {
                if(response.data.length){
                    response.data.forEach(dtc => {
                        this.dtcItems.push({dtc: dtc.code, name: dtc.description, activationTime: this.convertEpochTime(dtc.activation_time), deactivationTime: this.convertEpochTime(dtc.deactivation_time)});
                    })
                }
            }, error => {
                console.log('Axios error on getTruckDtcInfo() ==> ' + error);
            })    
        },
        createClusters: function() {
            let vm = this;
            // Add a marker clusterer to manage the markers.
            let mStyles = [
                {
                    url: '../images/m/m1.png',
                    height: 43,
                    width: 220,
                    anchor: [-10, -15],
                    textColor: '#000000',
                    textSize: 20,
                },
            ];
            vm.markerCluster = new MarkerClusterer(vm.myGoogleMap, vm.markers, { imagePath: '/images/m', minimumClusterSize: 2 });
            vm.markerCluster.setStyles(mStyles);
            // console.log('Clusters created...');
        },
        destroyClusters: async function() {
            let vm = this;
            if (vm.markerCluster) {
                await vm.markerCluster.clearMarkers();
                vm.markerCluster = null;
            }
            // console.log('Marker cluster should be empty --> ' + vm.markerCluster);
        },
        clusterWindows: function(state) {
            let vm = this;
            let infoWindow;
            let cLength = vm.markerCluster.clusters_.length;
            let marker;
            let infoStr = '';
            // console.log('Info Window -- ');
            // console.log('cLength = ' + cLength);
            if (state === 'show') {
                for (let i = 0; i < cLength; i++) {
                    if (vm.markerCluster.clusters_[i].markers_.length > 1) {
                        // eslint-disable-next-line
                        infoWindow = new google.maps.InfoWindow({
                            // eslint-disable-next-line
                            pixelOffset: new google.maps.Size(0, -20),
                        });
                        infoStr = '<span style="color:#000000;">';
                        for (marker of vm.markerCluster.clusters_[i].markers_) {
                            infoStr += marker.company + ': ' + marker.number + '<br>';
                        }
                        infoStr += '</span>';
                        infoWindow.setContent(infoStr);
                        infoWindow.setPosition(vm.markerCluster.clusters_[i].getCenter());
                        infoWindow.open(vm.myGoogleMap);
                        vm.infoWindow.push(infoWindow);
                    }
                }
                // console.log('Clusters Infowindows displayed...');
            } else {
                let wLength = vm.infoWindow.length;
                for (let j = 0; j < wLength; j++) {
                    vm.infoWindow[j].close();
                }
                vm.infoWindow.length = 0;
                // console.log('Clusters Infowindows removed...');
            }
        },
        buildMapMarkers: function(item) {
            let vm = this;
            // If we are in Kiosk Mode, don't process on one minute updates
            // The marker-rotation makes it's own calls to buildMapMarkers that
            // will only build the appropriate markers for the current rotation.
            if (!item && vm.kioskMode) {
                return;
            }

            vm.cleanMarkers();
            if (vm.truckUpdateCounter < 2) {
                vm.truckData.sort(vm.sortTruckArray);
            }
            let testLength = vm.truckData.length;
            let testLat = 0,
                testLng = 0;
            let tmpStr, tmpPos;
            let indexCount = 0;
            let iconObj;
            vm.markers.length = 0;
            for (let i = 0; i < testLength; i++) {
                tmpStr = '';
                //increment stop check value
                if (vm.truckData[i].stopCheck >= 1) {
                    vm.truckData[i].stopCheck += 1;
                }
                // if truck is moving, reset stop check to zero
                if (vm.truckData[i].speed > 0) {
                    vm.truckData[i].stopCheck = 0;
                }
                if (vm.truckData[i].position) {
                    testLat = parseFloat(vm.truckData[i].position.lat);
                    testLng = parseFloat(vm.truckData[i].position.lng);
                } else {
                    testLat = vm.hqLocation.lat;
                    testLng = vm.hqLocation.lng;
                }
                iconObj = vm.getCompanyIcon(vm.truckData[i].company);
                // eslint-disable-next-line
                tmpPos = new google.maps.LatLng(testLat, testLng);
                if (iconObj) {
                    tmpStr =
                        '<div style="display:block;position:absolute;width:100%;height:43px;z-index:400"><img src="/images/logos/' +
                        iconObj.logoName +
                        '" width="' +
                        iconObj.width +
                        '" height="' +
                        iconObj.height +
                        '" style="position:relative;top:' +
                        iconObj.top +
                        ';left:68px;" /></div>';
                }
                tmpStr +=
                    '<div style="display:block;position:relative;width:46px;height:43px;z-index:900;color:#FFFFFF;top:20%;left:80%;">' +
                    vm.truckData[i].number +
                    '</div></div>';
                if (testLat !== 0 && testLng !== 0) {
                    if (item && item === vm.truckData[i].company) {
                        vm.markers.push(
                            new RichMarker.RichMarker({
                                position: tmpPos,
                                map: vm.myGoogleMap,
                                anchor: RichMarker.RichMarkerPosition.MIDDLE,
                                company: vm.truckData[i].company,
                                id: indexCount,
                                number: vm.truckData[i].number,
                                title: vm.truckData[i].truckinfo.name,
                                content: vm.markerContent + tmpStr,
                                flat: true,
                                visible: true,
                            })
                        );
                    }
                    if (!item || item === 'All Trucks') {
                        vm.markers.push(
                            new RichMarker.RichMarker({
                                position: tmpPos,
                                map: vm.myGoogleMap,
                                anchor: RichMarker.RichMarkerPosition.MIDDLE,
                                company: vm.truckData[i].company,
                                id: indexCount,
                                number: vm.truckData[i].number,
                                title: vm.truckData[i].truckinfo.name,
                                content: vm.markerContent + tmpStr,
                                flat: true,
                                visible: true,
                            })
                        );
                    }
                    if (!vm.isNullOrUndefined(vm.markers[i])) {
                        vm.$set(vm.markers[i], 'status', vm.truckData[i].status);
                    }
                }
            }
            vm.markersSortExtra();
            return false;
        },
        setStatus: async function(item, status, statusType) {
            let vm = this;
            // console.log(`setStatus: truck = ${item.name}, status = ${status}`);
            // save the status 
            let saveResponse = await vm.saveStatus(item.id, status, statusType);
            if (saveResponse.status === 204 || saveResponse.status === 200) {
                if (statusType === 'status') {
                    item.status = status;
                    item.statusIcon = vm.statusIcons[status];
                    vm.updateDaysSince(status, item);
                } else if (statusType === 'truckStatus') {
                    item.truck_status = status;
                    item.truckStatusIcon = vm.statusIcons[status];
                } else if (statusType === 'service') {
                    item.daysStatusIcon = vm.statusIcons[status];
                }
                // find the corresponding Rich Marker and set status for that marker
                let index = _.findIndex(vm.markers, { company: item.company, number: item.number });
                if (index >= 0) {
                    if (statusType === 'status') {
                        vm.markers[index].status = status;
                        vm.markers[index].in_service = item.in_service;
                    } else if (statusType === 'truckStatus') {
                        vm.markers[index].truckStatus = status;
                    } else if (statusType === 'service') {
                        // no action yet
                    }
                }
            } else {
                // let statusString = isSystemStatus ? "status" : "truckStatus";
                console.log(`setStatus: Failed to set the ${statusType} for truck ${item.name} to ${status}: ${saveResponse.statusText}`);
                // TODO ?? Should a toast/vuetify snackbar inform user of failure to set/save the status
            }
        },
        saveStatus: async function(truck_id, status, statusType) {
            let vm = this;
            let patchResponse = {};
            let dataOps;
            // let statusString = isSystemStatus ? "status" : "truckStatus";
            let statusString = statusType;
            let source = `${vm.queryPathTrucks}/${statusString}/${truck_id}`;
            let itemState = false;
            let currDateTime = Math.round(new Date().getTime() / 1000);
            if (statusString === 'status' || statusString === 'truckStatus') {
                dataOps = { status: status };
            } else if (statusString === 'service') {
                itemState = status !== 'Normal';
                dataOps = { in_service: itemState, service_time: currDateTime };
            }
            await axios
                .patch(source, dataOps, vm.authConfig)
                .then(response => {
                    patchResponse = response;
                })
                .catch((error) => {
                    patchResponse = error.response;
                    console.error(error);
                });
            return patchResponse;
        },
        updateDaysSince: function(status, item) {
            let in_service = item.in_service;
            let needToUpdate = false;
            let daysSince = 0;
            if (status === 'Normal' && !in_service) {
                in_service = true;
                needToUpdate = true;
                daysSince = 1;
            } else if ((status === 'Critical' || status === 'Service') && in_service) {
                in_service = false;
                needToUpdate = true;
                daysSince = -1;
            }
            if (needToUpdate) {
                item.in_service = in_service;
                item.daysStatus = daysSince >= 0 ? 'Normal' : 'Critical';
            }
        },
        setAction: async function(item) {
            let vm = this;
            let action = item.action;
            // console.log(`setAction: truck = ${item.name}, action = ${action}`);
            // save the status 
            let saveResponse = await vm.saveAction(item.id, action);
            if (saveResponse.status === 204 || saveResponse.status === 200) {
                // find the corresponding Rich Marker and set action for that marker
                let index = _.findIndex(vm.markers, { company: item.company, number: item.number });
                if (index >= 0) {
                    vm.markers[index].action = action;
                }
            } else {
                console.log(`setAction: Failed to set the action for truck ${item.name} to ${action}`);
                // TODO ?? Should a toast/vuetify snackbar inform user of failure to set/save the status
            }
        },
        saveAction: async function(truck_id, action) {
            console.log('saveAction');
            let vm = this;
            let patchResponse = {};
            let source = `${vm.queryPathTrucks}/action/${truck_id}`;
            await axios
                .patch(source, { "action": action }, vm.authConfig)
                .then(response => {
                    patchResponse = response;
                })
                .catch((error) => {
                    patchResponse = error.response;
                    console.error(error);
                });
            return patchResponse;
        },
        setIssue: async function(item) {
            let vm = this;
            let issue = item.issue;
            // console.log(`setAction: truck = ${item.name}, issue = ${issue}`);
            // save the status 
            let saveResponse = await vm.saveIssue(item.id, issue);
            if (saveResponse.status === 204 || saveResponse.status === 200) {
                // find the corresponding Rich Marker and set issue for that marker
                let index = _.findIndex(vm.markers, { company: item.company, number: item.number });
                if (index >= 0) {
                    vm.markers[index].issue = issue;
                }
            } else {
                console.log(`setIssue: Failed to set the issue for truck ${item.name} to ${issue}`);
                // TODO ?? Should a toast/vuetify snackbar inform user of failure to set/save the status
            }
        },
        saveIssue: async function (truck_id, issue) {
            let vm = this;
            let patchResponse = {};
            let source = `${vm.queryPathTrucks}/issue/${truck_id}`;
            // console.log(`saveAction: source = ${source}`);
            await axios
                .patch(source, { "issue": issue }, vm.authConfig)
                .then(response => {
                    patchResponse = response;
                })
                .catch((error) => {
                    patchResponse = error.response;
                    console.error(error);
                });
            return patchResponse;
        },
    },
};
</script>

<!-- Global styles -->
<style>
.application--wrap {
    min-height: 0;
}

body {
    background-color: #303030;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.kioskToggleGroup {
    /* vertical-align: bottom; */
    float: right;
    margin: 10px;
}
.dark-adjust {
    background-color: #303030;
}
.line-title {
    font-weight: bold;
}
.page-title {
    display: inline-block;
    position: relative;
    left: 40px;
    top: -30px;
    padding-top: 25px;
    color: white;
    font-size: 24px;
    font-family: Roboto, sans-serif;
}
.sideTable {
    vertical-align: top;
    display: inline-block;
    position: relative;
    width: 20%;
    top: 0px;
}
.narrow-col {
    min-width: 0;
}
#map {
    vertical-align: top;
    display: inline-block;
    position: relative;
    width: 80%;
    height: 700px;
}
.data {
    margin-top: 0px;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
h3 {
    margin-top: 40px;
    text-align: left;
    font-size: 48px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 10px;
}
.key-name {
    margin-left: 6px;
    color: #fff;
    font-size: 2.5vw;
    font-weight: 400;
}
.value-props {
    margin-left: 6px;
    color: #fff;
    font-size: 2vw;
    font-weight: 300;
}
.line-data {
    margin-left: 6px;
    color: #fff;
    font-size: 24px;
    font-weight: 300;
}
/* centered global loader */
.loader {
    position:fixed;
    top:50%;
    left:50%;
    height:auto;
    width:auto;
    z-index:1;
    transform: translate(-50%, -50%);
}
/* table header height */
table.v-table thead tr {
    /* height: 45px; */
}
/* table rows/data height */
table.v-table tbody td,
table.v-table tbody th {
    height: 40px;
    /* padding: 0px 15px;
    min-width: 1% !important; */
}
/* table rows/data font-weight and size */
table.v-table tbody td {
    font-weight: 300;
    font-size: 16px;
}
tbody tr:nth-of-type(odd) {
    background-color: #383838;
}
/* action for range controls of table: the footer where the pagination controls are location */
.v-datatable__actions__range-controls {
    min-height: 30px;
    /* height: 40px; */
}

.Normal {
    color: #48ff48;
}
.Warning {
    color: #ffff48;
}
.SevereWarning {
    color:#f5bc53;
}
.Critical {
    color: #ff4848;
}
.Service {
    color: #ff4848;
}
.TruckService {
    color: #949494;
}

@media screen and (max-width: 750px) {
    .page-title {
        font-size: 18px;
    }
    .key-name {
        font-size: 11px;
    }
    .value-props {
        font-size: 10px;
    }
    h3 {
        text-align: center;
        font-size: 22px;
    }
    table.v-table tbody td {
        font-weight: 400;
        font-size: 9px;
    }
    table.v-table thead th {
        font-weight: 500;
        font-size: 9px;
    }
}
</style>
