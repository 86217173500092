<template>
    <div id="app">
        <!-- <link
            rel="stylesheet"
            href="node_modules/@fortawesome/fontawesome-free/css/all.css"
        > -->
        <!-- <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
            integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
            crossorigin="anonymous"
        > -->
        <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
        <MapView msg="This is where the data goes for Maps ..." />
    </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import MapView from "./components/MapView.vue";

export default {
    name: "app",
    components: {
        MapView
    }
};
</script>

<style>
html {
    overflow-y: auto;
}
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
    margin-top: 0px;
}
</style>
